import { BLOG_URI } from "./index";

export const PATHS = {
  INDEX: "/",
  CAREERS: "/careers/",
  CREDENTIALS: "/company-credentials/",
  MEET_OUR_TEAM: "/meet-our-team/",
  VOLUNTEERS: "/volunteers/",
  SERVICES: "https://homecare.life/services/",
  ABOUT_US: "https://homecare.life/about-us/",
  CONTACT: "https://homecare.life/contact-us/",
  BLOG: `${BLOG_URI}/sample`,
  PDF_TREATMENT: "/pdf/TreatmentReferralForm.pdf",
  MAP_LINK:
    "https://www.google.com/maps/d/embed?mid=1uWMCk1yySHUgR5W_M2cxkaQT4dcrRM5Z&ehbc=2E312F",
  OUR_COMPANY_CREDENTIALS: "https://homecare.life/our-company-credentials/",
  BLOG_MENU: "https://homecare.life/blog/",
  GET_STARTED: "https://homecare.life/get-started/",
  FAQ: "/faq",
  MEMORIAL: "/memorial",
  ABOUTUS: "/about-us",
};

export const CONTACT = {
  PHONE_TO_DISPLAY: "973-607-4930",
  PHONE: "9736074930",
  MAIL: "hello@hospicecare.life",
};

export const SOCIAL = {
  FACEBOOK: "https://www.facebook.com/lifehomecarenewjersey",
  INSTAGRAM: "https://www.instagram.com/lifehomecare.nj/",
  LINKEDIN: "https://www.linkedin.com/company/lifehomecarenewjersey/",
  HOME_CARE: "https://homecare.life/",
  RIDE: "https://ride.life/",
  DME: "https://www.mydme.life/",
};

export const SERVICES = {
  HOME_THERAPY: "https://www.hometherapy.life/",
  HOME_CARE: "https://homecare.life/",
  RIDE: "https://ride.life/",
  DME: "https://www.mydme.life/",
};
