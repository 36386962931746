import React from "react";

import { Link } from "gatsby";
import { CONTACT, PATHS, SERVICES } from "../../config/urls";

import logoHospice from "../../images/life-hospice-logo.svg";
import ArrowDown from "../../images/arrow-down.svg";
import hamburguerIcon from "./assets/hamburguer-menu-icon.svg";
import phoneIcon from "../../images/phone-white.svg";
import PhoneIcon from "../../images/phone-topbar.svg";
import {
  Container,
  MenuContainer,
  MenuDesktop,
  ContactUs,
  MenuDesktopBrand,
  MenuDesktopList,
  MenuDesktopItem,
  MenuDesktopServicesItem,
  Arrow,
  DropdownContent,
  MenuMobile,
  MenuMobileButton,
  HamburguerIcon,
  MobileServices,
  WhiteLine,
  PhoneContainer,
  PhoneImage,
  PhoneText,
  LocationPoint,
} from "./styles";

const Topbar = () => {
  const [open, setOpen] = React.useState(false);
  const [openMobileServices, setOpenMobileServices] = React.useState(false);

  return (
    <>
      <MenuContainer>
        <MenuDesktop>
          <Container>
            <MenuDesktopBrand>
              <Link to={PATHS.INDEX}>
                <img src={logoHospice} alt="Logo Hospice" />
              </Link>
            </MenuDesktopBrand>
            <MenuDesktopList>
              <MenuDesktopServicesItem>
                Other services <img src={ArrowDown} alt="arrow down" />
                <div>
                  <a href={SERVICES.HOME_CARE}>home care</a>
                  <a href={SERVICES.HOME_THERAPY}>home therapy</a>
                  <a href={SERVICES.DME} target="_blank" rel="noreferrer">
                    durable medical equipment
                  </a>
                  <a href={SERVICES.RIDE}>ride</a>
                </div>
              </MenuDesktopServicesItem>
              {/* <Link to={PATHS.ABOUTUS}>
                <MenuDesktopItem>About us</MenuDesktopItem>
              </Link> */}
              <Link to={PATHS.VOLUNTEERS}>
                <MenuDesktopItem>Volunteers</MenuDesktopItem>
              </Link>

              <Link to={PATHS.FAQ}>
                <MenuDesktopItem>FAQ</MenuDesktopItem>
              </Link>
              {/* <Link to={PATHS.MEMORIAL}>
                <MenuDesktopItem>Memorials</MenuDesktopItem>
              </Link> */}
              <PhoneContainer>
                <PhoneImage src={PhoneIcon} />
                <PhoneText>973-607-4930</PhoneText>
              </PhoneContainer>
              <MenuDesktopItem>
                <ContactUs href="#callBack">
                  <LocationPoint src={phoneIcon} />
                  CONTACT US
                </ContactUs>
              </MenuDesktopItem>
            </MenuDesktopList>
          </Container>
        </MenuDesktop>

        {/* MOBILE */}

        <MenuMobile>
          <Container>
            <MenuMobileButton>
              <HamburguerIcon
                onClick={() => setOpen(!open)}
                src={hamburguerIcon}
                alt="hamburguer menu"
              />
              <DropdownContent isOpen={open}>
                <ul>
                  <Link to={PATHS.INDEX}>home</Link>
                  <li
                    onClick={() => setOpenMobileServices(!openMobileServices)}
                  >
                    other services{" "}
                    <Arrow
                      isServicesOpen={openMobileServices}
                      src={ArrowDown}
                    />
                  </li>
                  <MobileServices isServicesOpen={openMobileServices}>
                    <WhiteLine />
                    <a href={SERVICES.HOME_CARE}>home care</a>
                    <a href={SERVICES.HOME_THERAPY}>home therapy</a>
                    <a href={SERVICES.DME} target="_blank" rel="noreferrer">
                      durable medical equipment
                    </a>
                    <a href={SERVICES.RIDE}>ride</a>
                    <WhiteLine />
                  </MobileServices>
                  {/* <Link to={PATHS.ABOUTUS}>About us</Link> */}

                  <Link to={PATHS.VOLUNTEERS}>volunteers</Link>
                  <Link to={PATHS.FAQ}>FAQ</Link>
                  {/* <Link to={PATHS.MEMORIAL}>Memorials</Link> */}
                </ul>
              </DropdownContent>
            </MenuMobileButton>
            <MenuDesktopBrand>
              <Link to={PATHS.INDEX}>
                <img src={logoHospice} alt="Logo Hospice" />
              </Link>
            </MenuDesktopBrand>
          </Container>
        </MenuMobile>
      </MenuContainer>
    </>
  );
};

export default Topbar;
